/* eslint-disable @next/next/no-css-tags */
import { Amplify } from "@aws-amplify/core";
import Head from "next/head";
import dynamic from "next/dynamic";
import Script from "next/script";

// const GAPageViews = dynamic(() => import("lib/ga/GAPageViews"), { ssr: false });
const FBSDK = dynamic(() => import("lib/fb/FBSDK", { ssr: false }));
const MobileMenu = dynamic(() => import("@components/MobileMenu"), {
  ssr: false,
});
const Rewardful = dynamic(() => import("lib/Rewardful"), { ssr: false });
// const Hubspot = dynamic(() => import("lib/hubspot/Hubspot"), { ssr: false });
const Hotjar = dynamic(() => import("lib/Hotjar"), { ssr: false });
const Notifications = dynamic(() => import("@components/NotificationPopup"), {
  ssr: false,
});

const ApplicationImageViewer = dynamic(() => import("@components/ApplicationImageViewer"), {
  ssr: false,
});

import "../styles/globals.css";
import "../styles/wordpress.css";
import "semantic-ui-css/semantic.min.css";
import "react-notifications/lib/notifications.css";
import "react-toastify/dist/ReactToastify.css";
import "../styles/flaticon.css";
import AdminToggle from "@components/Admin/AdminToggle";
const AuthProvider = dynamic(() =>
  import("../src/auth").then((mod) => mod.AuthProvider)
);
const StateProvider = dynamic(() =>
  import("@src/state").then((mod) => mod.StateProvider)
);

// const AdminToggle = dynamic(() =>
//   import("@components/Admin/AdminToggle").then((mod) => mod.AdminToggle)
// );

const config = {
  aws_appsync_region: process.env.NEXT_PUBLIC_APP_SYNC_REGION,
  aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_APP_SYNC_ENDPOINT,
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: process.env.NEXT_PUBLIC_APP_SYNC_API_KEY,
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
  federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
  aws_cognito_signup_attributes:
    process.env.NEXT_PUBLIC_COGNITO_SIGNUP_ATTRIBUTES.split(","),
  aws_cognito_mfa_configuration: "OPTIONAL",
  aws_cognito_mfa_types: process.env.NEXT_PUBLIC_COGNITO_MFA_TYPES,
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: parseInt(
      process.env.NEXT_PUBLIC_COGNITO_MFA_TYPES
    ),
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms:
    process.env.NEXT_PUBLIC_COGNITO_VERIFICATION_MECHANISMS.split(","),
  aws_cloud_logic_custom: [
    {
      name: process.env.NEXT_PUBLIC_COGNITO_LOGIC_CUSTOM_NAME,
      endpoint: process.env.NEXT_PUBLIC_COGNITO_LOGIC_CUSTOM_ENDPOINT,
      region: process.env.NEXT_PUBLIC_COGNITO_LOGIC_CUSTOM_REGION,
    },
  ],
  aws_cognito_login_mechanisms:
    process.env.NEXT_PUBLIC_COGNITO_LOGIN_MECHANISMS.split(","),
  aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_S3_USER_FILES_BUCKET,
  aws_user_files_s3_bucket_region:
    process.env.NEXT_PUBLIC_S3_USER_FILES_BUCKET_REGION,
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGNIN,
    redirectSignOut: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGNOUT,
    responseType: "code",
  },
  ssr: true,
};

Amplify.configure(config);

function MyApp({ Component, pageProps }) {
  const getLayout = Component.getLayout || ((page) => page);

  return (
    <div id="body">
      <Head>
        <meta name="viewport" content="width=device-width" />
        <meta charSet="UTF-8" />
        <meta name="p:domain_verify" content="f9f45b48b019ccf33eebf636c70e7e39"/>
      </Head>
      <Script
        id="places api"
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_PLACES_API_KEY}&libraries=places&callback=Function.prototype`}
        strategy="beforeInteractive"
        onLoad={() => console.log("loaded places api")}
      />
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS_CODE}`}
      />
      <Script
            id="google-ads"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS_CODE}');
              `,
            }}
      />      
      <Script id="heap-analytics" type="text/javascript" strategy="lazyOnload">
        {`
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load(${process.env.NEXT_PUBLIC_HEAP_ID});        
        `}
      </Script>
      <FBSDK />
      <Hotjar />
      <Rewardful />
      {/* <Hubspot /> */}
      <Notifications />
      <StateProvider>
        <AuthProvider pageProps={pageProps}>
          {getLayout(<Component {...pageProps} />)}
          <MobileMenu />
          <ApplicationImageViewer />
          <AdminToggle />
        </AuthProvider>
      </StateProvider>
    </div>
  );
}

export default MyApp;

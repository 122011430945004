import { Button } from "semantic-ui-react";
import { useAuth } from "../../../src/auth";
import { userIsAdmin } from "@src/helpers";

export default function AdminToggle() {
    const { signOutUser, user, adminUser, revert } = useAuth();
    const isAdmin = userIsAdmin(user);

    console.log("user", user);  
    console.log("adminUser", adminUser);

    if (user && adminUser && isAdmin && user?.username !== adminUser?.username) {
        return (
            <div
                style={{
                    position: "fixed",
                    top: "0",
                    left: "50%",
                    transform: "translateX(-50%)",
                    padding: "10px",
                    backgroundColor: "red",
                    color: "white",
                    zIndex: 10000000000,
                }}
            >
            {`You are impersonating ${user?.name}`}{" "}
            <Button size="tiny" secondary onClick={() => revert()}>
              Stop Impersonating
            </Button>
          </div>            
        )
    }   

    return null;
}
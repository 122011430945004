import React, { useState, useEffect, useContext, createContext } from "react";
import { ApolloProvider } from "@apollo/client";
import { Hub, Auth } from "aws-amplify";
import { useApollo } from "./apollo";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

import { userIsCandidate, userIsEnterprise, userIsAdmin } from "./helpers";
import { store, useAppState } from "@src/state";

const authContext = createContext();

export function AuthProvider({ children, pageProps }) {
  const router = useRouter();
  const auth = useProvideAuth(router);
  const client = useApollo(pageProps.initialApolloState);
  const { dispatch } = useContext(store);
  const { dispatch: appDispatch, state: appState } = useAppState();
  const loginAction = () => dispatch({ type: "login" });
  const notLoading = () => dispatch({ type: "auth_loading_off" });

  Hub.listen("auth", (data) => {
    // console.log("auth event: ", data.payload.event);
    switch (data.payload.event) {
      case "signIn":
        console.log("USER DATA:", data.payload.data);
        auth.setUser(data.payload.data);
        if (userIsAdmin(data.payload.data)) {
          auth.setAdminUser(data.payload.data);
          appDispatch({
            type: "show_sidebar"
          });
        }
        // dispatch({
        //   type: "setEmailVerified",
        //   payload: data?.payload?.data?.attributes?.email_verified,
        // });
        Cookies.set("username", data.payload.data.username);

        if (
          data.payload.data.signInUserSession.accessToken.payload[
            "cognito:groups"
          ].indexOf("enterpriseowner") > -1
        ) {
          router.push("/employer/dashboard/jobs");
        } else {
          router.push("/candidate/dashboard/auto/");
        }
        break;
      case "signOut":
        Cookies.remove("username");
        auth.setUser(null);
        auth.setAdminUser(null);
        break;
      case "cognitoHostedUI":
        break;
      default:
        break;
    }
  });

  useEffect(() => {
    async function checkIfUserSignedIn() {
      try {
        const result = await Auth.currentAuthenticatedUser();
        const isUserAdmin = userIsAdmin(result);
        // console.log("is user admin: ", isUserAdmin);

        if (isUserAdmin) {
          auth.setAdminUser(result);
        }

        if (result?.username.includes("google")) {
          auth.setUser(result);
          loginAction();
        } else {
          const updatedUser = await Auth.updateUserAttributes(result, []);
          // console.log("updated user: ", updatedUser);
          // console.log("user checked: ", result);
          auth.setUser(result);
          loginAction();
        }
      } catch (error) {
        console.log("an error occurred: ", error);
        notLoading();
      }
    }

    checkIfUserSignedIn();
  }, []);

  return (
    <authContext.Provider value={auth}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth(router) {
  const [user, setUser] = useState(null);
  const [adminUser, setAdminUser] = useState(null);
  const { dispatch, state } = useContext(store);

  const resetStoreAction = () => dispatch({ type: "restore_initial_state" });
  const loginAction = () => dispatch({ type: "login" });

  const isLoading = state?.authLoading;

  const isSignedIn = () => {
    if (user) {
      return true;
    } else {
      return false;
    }
  };

  const signInUser = async (email, password, metadata, handleError) => {
    try {
      const user = await Auth.signIn(email, password, metadata);
      setUser(user);
      if (userIsAdmin(user)) {
        setAdminUser(user);
      }
      loginAction();
    } catch (error) {
      console.log("signin error: ", error);
      if (handleError) {
        handleError(error);
      }
    }
  };

  const getRedirectUrl = () => {
    if (userIsCandidate(user)) {
      return "/signin";
    } else {
      return "/employer/signin";
    }
  };

  const isCandidate = () => userIsCandidate(user);

  const isEnterprise = () => userIsEnterprise(user);

  const isAdmin = () => userIsAdmin(user) || userIsAdmin(adminUser);

  const signOutUser = async () => {
    let redirectUrl = getRedirectUrl();
    try {
      await Auth.signOut();
      setUser(null);
      setAdminUser(null);
      resetStoreAction();
      router.push(redirectUrl);
    } catch (error) {}
  };

  const deleteUser = async () => {
    const user = await Auth.currentAuthenticatedUser();

    user.deleteUser(async (error) => {
      if (error) {
        throw error;
      }

      Auth.signOut({ global: true });
    });
  };

  const revert = () => {
    setUser(adminUser);
  }

  return {
    isCandidate,
    isEnterprise,
    isSignedIn,
    isAdmin,
    isLoading: isLoading,
    signInUser,
    signOutUser,
    deleteUser,
    user,
    setUser,
    adminUser,
    setAdminUser,
    revert
  };
}


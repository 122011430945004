import { gql } from '@apollo/client';
import { Auth } from "aws-amplify";
import Router from 'next/router';
import { getCandidateById, getEmployerByIdWithoutPostings } from 'queries';
import findIndex from 'lodash.findindex'
import axios from 'axios';

export async function signOut() {
    try {
        await Auth.signOut()
        Router.push('/')
    } catch(error) {
    }
}

function getGroupsForCurrentSession(current) {
    if (current) {
        return current?.idToken?.payload['cognito:groups']
    }
}

export function currentSessionIsCandidate(current) {
    if (current) {
        const groups = getGroupsForCurrentSession(current)
        return groups.indexOf('candidate') > -1
    }
    return false 
}

function getGroupsForUser(user: any): Array<string> {
    return user?.signInUserSession?.accessToken?.payload['cognito:groups'] || []
}

export function userIsCandidate(user: any): boolean {
    let groups = getGroupsForUser(user)

    if (groups.indexOf('candidate') > -1) {
        return true
    }
}

export function userIsAdmin(user: any): boolean {
    let groups = getGroupsForUser(user)

    if (groups.indexOf('superadmin') > -1) {
        return true
    }

    return false
}

export function userIsEnterprise(user: any): boolean {
    let groups = getGroupsForUser(user)

    if (groups.indexOf('enterpriseowner') > -1 || groups.indexOf('enterpriserecruiter') > -1)  {
        return true
    }
    
    return false
}

export async function getJobSeekerForSSR(context, apolloClient, user){
    const { data } = await apolloClient.query({
        query: gql(getCandidateById),
        variables: {
            userId: user.username
        }
    })   
    
    const { getUserById: { jobSeeker } } = data

    return { jobSeeker }    
}

export async function getCompanyProfileForSSR(context, apolloClient, user){
    const { data } = await apolloClient.query({
        query: gql(getEmployerByIdWithoutPostings),
        variables: {
            userId: user.username
        }
    })   
    
    const { getUserById: { company } } = data

    return { company }    
}

export const getApplicationDateForJobPostId = (applications, postId) => {
    let appliedIndex = findIndex(applications, (o) => o.jobId === postId)
    let applicationDate
    if (appliedIndex > -1) {
        applicationDate = applications[appliedIndex]?.dateApplied.substring(0,10)
    }
    return applicationDate
}

  export function hasSpecialCharacter(value) {
    const code = "[$&+,:;=?@#|'<>.^*()%!-]"
    const regex = new RegExp(code)
    return regex.test(value)
  }

  export function getAmplifyConfig() {
    return {
        aws_appsync_region: process.env.NEXT_PUBLIC_APP_SYNC_REGION,
        aws_appsync_graphqlEndpoint: process.env.NEXT_PUBLIC_APP_SYNC_ENDPOINT,
        aws_appsync_authenticationType: "API_KEY",
        aws_appsync_apiKey: process.env.NEXT_PUBLIC_APP_SYNC_API_KEY,
        aws_project_region: "us-east-1",
        aws_cognito_region: "us-east-1",
        aws_cognito_identity_pool_id: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
        aws_user_pools_id: process.env.NEXT_PUBLIC_USER_POOL_ID,
        aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
        federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS",
        aws_cognito_signup_attributes:
        process.env.NEXT_PUBLIC_COGNITO_SIGNUP_ATTRIBUTES.split(","),
        aws_cognito_mfa_configuration: "OPTIONAL",
        aws_cognito_mfa_types: process.env.NEXT_PUBLIC_COGNITO_MFA_TYPES,
        aws_cognito_password_protection_settings: {
        passwordPolicyMinLength: parseInt(
            process.env.NEXT_PUBLIC_COGNITO_MFA_TYPES
        ),
        passwordPolicyCharacters: [],
        },
        aws_cognito_verification_mechanisms:
        process.env.NEXT_PUBLIC_COGNITO_VERIFICATION_MECHANISMS.split(","),
        aws_cloud_logic_custom: [
        {
            name: process.env.NEXT_PUBLIC_COGNITO_LOGIC_CUSTOM_NAME,
            endpoint: process.env.NEXT_PUBLIC_COGNITO_LOGIC_CUSTOM_ENDPOINT,
            region: process.env.NEXT_PUBLIC_COGNITO_LOGIC_CUSTOM_REGION,
        },
        ],
        aws_cognito_login_mechanisms:
        process.env.NEXT_PUBLIC_COGNITO_LOGIN_MECHANISMS.split(","),
        aws_user_files_s3_bucket: process.env.NEXT_PUBLIC_S3_USER_FILES_BUCKET,
        aws_user_files_s3_bucket_region:
        process.env.NEXT_PUBLIC_S3_USER_FILES_BUCKET_REGION,
        oauth: {
        domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
        scope: ["email", "openid", "profile"],
        redirectSignIn: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGNIN,
        redirectSignOut: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGNOUT,
        responseType: "code",
        },
        ssr: true,
    };    
  }

  export function configureAWS(aws, region?: string) {
    aws.config.update({
        'credentials': {
           'accessKeyId': process.env.AWS_ACCESS_KEY_ID_MYAPP,
           'secretAccessKey': process.env.AWS_SECRET_ACCESS_KEY_MYAPP
          },
        'region': process.env.AWS_DEFAULT_REGION_MYAPP,
      })
      
      if (region) {
        aws.config.region = region;
      }
  }

export const getNumberWithCommas = (value) => {
    if (isNaN(value)) return null

    if (value) {
      const res = Number.parseFloat(value).toLocaleString('en-US')
      return res ?? value
    }

    return value ?? null    
}

export const isUrl = (text) => {
    if (!text.length) return true;

    if (
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/.test(
        text.trim()
      )
    ) {
      return true;
    }
    return false;
};

let placeAddressComponent = {
    ZIP_CODE: "postal_code",
    COUNTRY: "country",
    STATE: "administrative_area_level_1",
    CITY: "administrative_area_level_2",
    TOWN: "sublocality_level_1",
    AREA: "sublocality_level_2",
    NEAREST_ROAD: "route",
  };

export function getAddressCountry(value, server?: boolean) {
    if (server) {
        return getAddressComponent(
            value,
            placeAddressComponent.COUNTRY
          );
    }

    if (value?.gmaps?.address_components) {
        return getAddressComponent(
            value?.gmaps?.address_components,
            placeAddressComponent.COUNTRY
          );
    }
}

export function getAddressState(value, server?: boolean) {
    if (server) {
        return getAddressComponent(
            value,
            placeAddressComponent.STATE
          );        
    }
    if (value?.gmaps?.address_components) {
        return getAddressComponent(
            value?.gmaps?.address_components,
            placeAddressComponent.STATE
          );
    }
}

export function getAddressComponent(address_components, key) {
    var value = "";
    var postalCodeType = address_components.filter((aComp) =>
      aComp.types.some((typesItem) => typesItem === key)
    );
    if (postalCodeType != null && postalCodeType.length > 0)
      value = postalCodeType[0].short_name;
    return value;
  }

  export function getAreaForCountry(countryCode) {
    if (!countryCode) return ""
    
    console.log('contry code: ', countryCode)

    switch(countryCode.toLowerCase()) {
        case 'ar':
        case "br":
        case "bo":
        case 'cl':
        case 'co':
        case 'cr':
        case 'cu':
        case 'do':
        case 'ec':
        case 'sv':
        case 'gt':
        case 'hn':
        case 'ni':
        case 'pa':
        case 'pe':
        case 'pr':
        case 'uy':
        case "ve":
        case "py":
            return 'latam'
        case "dz":
        case "ao":
        case "bj":
        case "bw":
        case "bf":
        case "bi":
        case "cm":
        case "cv":
        case "cf":
        case "td":
        case "km":
        case "cg":
        case "cd":
        case "ci":
        case "dj":
        case "eg":
        case "gq":
        case "er":
        case "et":
        case "ga":
        case "gm":
        case "gh":
        case "gn":
        case "gw":
        case "ke":
        case "ls":
        case "lr":
        case "ly":
        case "mg":
        case "mw":
        case "ml":
        case "mr":
        case "mu":
        case "ma":
        case "mz":
        case "na":
        case "ne":
        case "ng":
        case "rw":
        case "st":
        case "sn":
        case "sc":
        case "sl":
        case "so":
        case "za":
        case "ss":
        case "sd":
        case "sz":
        case "tz":
        case "tg":
        case "tn":
        case "ug":
        case "eh":
        case "zm":
        case "zw":     
            return "africa"
        case "ae":
        case "am":
        case "az":
        case "bh":
        case "cy":
        case "ge":
        case "ir":
        case "iq":
        case "il":
        case "jo":
        case "kw":
        case "lb":
        case "om":
        case "ps":
        case "qa":
        case "sa":
        case "sy":
        case "tr":
        case "ye": 
            return "mide"               
        case "af":
        case "am":
        case "az":
        case "bh":
        case "bd":
        case "bt":
        case "bn":
        case "kh":
        case "cn":
        case "cy":
        case "ge":
        case "hk":
        case "in":
        case "id":
        case "ir":
        case "iq":
        case "il":
        case "jp":
        case "jo":
        case "kz":
        case "kw":
        case "kg":
        case "la":
        case "lb":
        case "mo":
        case "my":
        case "mv":
        case "mn":
        case "mm":
        case "np":
        case "om":
        case "pk":
        case "ps":
        case "ph":
        case "qa":
        case "sa":
        case "sg":
        case "kr":
        case "lk":
        case "sy":
        case "tw":
        case "tj":
        case "th":
        case "tl":
        case "tr":
        case "tm":
        case "ae":
        case "uz":
        case "vn":
        case "ye":   
            return "asia"   
        case "al":
        case "ad":
        case "at":
        case "by":
        case "be":
        case "ba":
        case "bg":
        case "hr":
        case "cy":
        case "cz":
        case "dk":
        case "ee":
        case "fi":
        case "fr":
        case "de":
        case "gi":
        case "gr":
        case "hu":
        case "is":
        case "ie":
        case "im":
        case "it":
        case "kz":
        case "xk":
        case "lv":
        case "li":
        case "lt":
        case "lu":
        case "mk":
        case "mt":
        case "md":
        case "mc":
        case "me":
        case "nl":
        case "no":
        case "pl":
        case "pt":
        case "ro":
        case "ru":
        case "sm":
        case "rs":
        case "sk":
        case "si":
        case "es":
        case "se":
        case "ch":
        case "ua":
        case "gb":
            return "euro"
        case "us":
        case "ca":
        case "mx":
            return "northam"
        default:
            return "no match"
    }
  }

export const getSalaryRangeFromDollarStringStripCommasAndDollarSign = (salaryRangeString) => {
      if (!salaryRangeString) return [0, 900]

    const salaries = salaryRangeString.split('-')
    const salaryStart = salaries[0].replace(/,/g, '').replace('$', '')
    const salaryEnd = salaries[1].replace(/,/g, '').replace('$', '')
    return [parseInt(salaryStart), parseInt(salaryEnd)]
}

export async function getAutoData(autoId, dateCreated) {
    try {
      const response = await axios.get(`/api/auto/plan/?id=${autoId}&dateCreated=${dateCreated}`)
      console.log('auto plan: ', response)
      return response?.data
    } catch (error) {
      console.log("error fetching autoapply: ", error)
    }
}

export const removeTypename = (data) => {
  // Handle null/undefined
  if (data == null) {
    return data;
  }

  // Handle arrays
  if (Array.isArray(data)) {
    return data.map(item => removeTypename(item));
  }

  // Handle objects
  if (typeof data === 'object') {
    const newObj = {};
    
    for (const [key, value] of Object.entries(data)) {
      if (key !== '__typename') {
        newObj[key] = removeTypename(value);
      }
    }
    
    return newObj;
  }

  // Return primitive values as is
  return data;
};
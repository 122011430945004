// here we are adapting our previous solution to the recommended NextJS approach
import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject,
} from "@apollo/client";

import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link';
import { Auth } from "aws-amplify";
import { useMemo } from "react";
import withSSRContext from "./helpers/withSSRContext";
  
let apolloClient: ApolloClient<NormalizedCacheObject>;

const url = process.env.NEXT_PUBLIC_APP_SYNC_ENDPOINT
const region = process.env.NEXT_PUBLIC_APP_SYNC_REGION

function createApolloLink(context) {

    let AuthyThing;

    if (context) {
      AuthyThing = (withSSRContext(context)).Auth
    } else {
      AuthyThing = Auth
    }

    const auth: AuthOptions = {
        type: 'AMAZON_COGNITO_USER_POOLS',
        jwtToken: async () => {
            try {
                return (await AuthyThing.currentSession()).getIdToken().getJwtToken()
            } catch (error) {
                return ""
            }
        } 
    }    

    const authTwo: AuthOptions = {
        type: "API_KEY",
        apiKey: process.env.NEXT_PUBLIC_APP_SYNC_API_KEY
    }

    let apiKeyLink = createAuthLink({
        url,
        region,
        auth: authTwo             
    })

    let userPoolLink = createAuthLink({
        url,
        region,
        auth             
    })    
    
    const awsLink = ApolloLink.split((operation) => {
      return isOperationPublic(operation)
    }, apiKeyLink, userPoolLink)

    function isOperationPublic(op) {
        switch (op.operationName) {
            case 'ListJobs':
            case 'GetCompanyProfileByCompanyId':
            case 'GetJobById':
            case 'GetFeaturedJobs':
            case 'SearchJobsByTerm':
            case 'SearchJobsByLocation':
            case 'SearchJobs':
                return true;
            default:
                return false 
        }
    }

  const httpLink = createHttpLink({ uri: url });

  return ApolloLink.from([
    awsLink,
    httpLink
  ])
}

export function createApolloClient(context) {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: createApolloLink(context),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, context?) {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }

  if (typeof window === "undefined") return _apolloClient;
  apolloClient = apolloClient ?? _apolloClient;

  return apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
  
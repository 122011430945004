import React, { useContext, useMemo, useReducer } from 'react'

type Action = {
  type: string
  payload?: any
}

type Dispatch = (action) => void
type State = any

const INITIAL_STATE = {
  latitude: '',
  longitude: '',
  locationName: '',
  userLoggedIn: false,
  authLoading: true,
  locationScriptLoaded: true,
  onboardingCompleted: null,
  userData: {
    age: null,
    companyId: null,
    email: null,
    emailVerified: false,
    firstName: null,
    group: null,
    id: null,
    lastName: null,
    onboardingCompleted: false,
  },
  notifications: [],
  showGuidanceTooltips: false,
  mobileMenu: false,
  sidebar: false,
  activeFormImage: null,
}

const store = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined)

const { Provider } = store

function reducer(state: State, { type, payload }: Action) {
  switch (type) {
    case 'clear_active_image':
      return {
        ...state,
        activeFormImage: null,
      }
    case 'set_active_image':
      return {
        ...state,
        activeFormImage: payload,
      }
    case 'setOnboardingCompleted':
      return {
        ...state,
        onboardingCompleted: payload,
      }
    case 'auth_loading_off': 
      return {
        ...state,
        authLoading: false
      }    
    case 'auth_loading': 
      return {
        ...state,
        authLoading: true
      }
    case 'show_guidance_tooltips':
      return {
        ...state,
        showGuidanceTooltips: payload,
      }
    case 'location_script_loaded':
      return {
        ...state,
        locationScriptLoaded: true,
      }
    case 'set_notifications':
      return {
        ...state,
        notifications: payload || [],
      }
    case 'open_mobile_menu':
      return {
        ...state,
        mobileMenu: true,
      }
    case 'close_mobile_menu':
      return {
        ...state,
        mobileMenu: false,
      }
    case 'show_sidebar':
      return {
        ...state,
        sidebar: true,
      }
    case 'hide_sidebar':
      return {
        ...state,
        sidebar: false,
      }
    case 'restore_initial_state':
      return {
        ...INITIAL_STATE,
        locationScriptLoaded: true,
      }
    case 'login':
      return {
        ...state,
        userLoggedIn: true,
        authLoading: false
      }
    case 'setLocation':
      return {
        ...state,
        latitude: payload.latitude,
        longitude: payload.longitude,
        locationName: payload.locationName,
      }
    default:
      return state
  }
}

function StateProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  const loaded = () => {
    dispatch({ type: 'location_script_loaded' })
  }

  return (
    <Provider value={contextValue}>
      <>
        {/* <Script
          id="places api"
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_PLACES_API_KEY}&libraries=places`}
          strategy="beforeInteractive"
          onLoad={loaded}
        /> */}
        {children}
      </>
    </Provider>
  )
}

export { StateProvider, store }

export function useAppState() {
  return useContext(store)
}
